import React from 'react';
import './App.css';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
// import bandImage from './band-picture.jpg';

const spotifyURL = "https://open.spotify.com/playlist/5d6iieNeDIUVcgwmeB0w1g?si=24fa173ee21b4c63";
const itunesURL = "https://music.apple.com/es/artist/rumia/1480216656?l=en";
const facebookURL = "https://www.facebook.com/rumiamusic/";
const instagramURL = "https://www.instagram.com/iamrumia/";
const youtubeURL = "https://www.youtube.com/c/Rumiamusic";
const shopifyURL = "https://shop.fluctuarecords.com/"

const concerts = [
  {
    date: "09-12-2022",
    name: "Fnac - A Coruña (ES)",
    linkName: "Free Entrance",
    linkURL: "https://www.songkick.com/concerts/40755585-rumia-at-fnac-plaza-lugo"
  },
  {
    date: "25-02-2023",
    name: "Sala Vesta - Madrid (ES)",
    linkName: "Tickets",
    linkURL: "https://www.songkick.com/concerts/40755591-rumia-at-sala-vesta"
  },
  {
    date: "28-04-2023",
    name: "La Disfrutona del Orzán (Ciclo Retroalimentación) - A Coruña (ES)",
    linkName: "Tickets",
    linkURL: "https://www.songkick.com/concerts/40755593-rumia-at-la-disfrutona-del-orzan"
  }
]

function App() {


  // function canPlay(e){
  //   e.preventDefault();

  //   const videoElement = document.getElementById("backgroundVideo")
  //   // console.log("canPlay, loop", videoElement.readyState)
  //   if(videoElement.readyState === 1){
  //     console.log("restart video")
  //     videoElement.load();
  //     videoElement.play()
  //   }
  // }
  
  return (
    <div className="App">
      <div style={{position: "fixed", zIndex: "-99", width: "300%", height: "100%", left: "-100%"}}>
        <iframe
          title="youtubeBackground"
          frameborder="0"
          style={{position: "absolute",top: 0, left: 0, width: "100%", height: "100%"}}
          height="100%"
          width="100%"
          src="https://youtube.com/embed/RpuTKmJD2Qc?autoplay=1&controls=0&showinfo=0&autohide=1&mute=1&deletecontrols=0&loop=1&playlist=RpuTKmJD2Qc"
        >
        </iframe>
      </div>

      <div className="Header-top-border">
          {/* Red border of the top */}
        </div>
        {/* <video playsInline autoPlay muted loop id="backgroundVideo" 
          src="/backgroundvideo.mp4" onCanPlay={canPlay}>
        </video> */}
      <header className="App-header">
        <div className="Header-textbox">
          {/* Box of text with Band name, release title and social media links */}
          <div className="Header-band-title">
            RUMIA
          </div>
          <div className="Header-release">
            {/* <div>
              EP 'NEXT ROUND' IS OUT
            </div> */}
            <div>
              <a href={spotifyURL} target="_blank" rel="noopener noreferrer">
                LISTEN ON SPOTIFY
              </a>
            </div>
          </div>
          <div className="Header-social-links">
            <p>
            <a href={itunesURL} target="_blank" rel="noopener noreferrer">
              ITUNES
            </a>
            </p>
            <p>
              <a href={spotifyURL} target="_blank" rel="noopener noreferrer">
                SPOTIFY
              </a>
            </p>
            <p>
              <a href={instagramURL} target="_blank" rel="noopener noreferrer">
                INSTAGRAM
              </a>
            </p>
            <p>
              <a href={youtubeURL} target="_blank" rel="noopener noreferrer">
                YOUTUBE
              </a>  
            </p>
            <p>
              <a href={facebookURL} target="_blank" rel="noopener noreferrer">
                FACEBOOK
              </a>
            </p>
            <p>
              <a href={shopifyURL} target="_blank" rel="noopener noreferrer">
                SHOP
              </a>
            </p>
          </div>
        </div>
      </header>
      

      <Grid container justify="center" className="Body-band-container">
        {/* <Grid item className="Body-band-picture" xs={10} md={5}>
          <img src={bandImage} alt="band"></img>
        </Grid> */}
        <Grid item className="Body-band-info" xs={10} md={5} >
          <div>
          <p>
          Berlin-based melancholic, indie pop heart artist Rumia, distinguishes with her profound lyrical perspectives and nostalgic sounds.
          </p>
          <p>
          Driven by the Berlin music scene, her songs have evolved into more synthetic and electronic soundscapes, but never abandoning the natural sounds of piano and acoustic guitar. A fresh proposal influenced by a wide array of 90s genres like Trip Hop, as well as modern pop like Feist or Taylor Swift. These influences have been guided by her love for lyrics, with storytelling being one of Rumia's most prominent abilities. Having started to share her deepest stories, based on her own experiences.
          </p>
          <p>
          Half Spanish, half Portuguese, Rumia moved to Berlin in (2018) to pursue her dreams and study songwriting. Since then, in the space of just a few years, Rumia has already received praise and radio support from Fritz Unsigned Germany, Radio 3 and RTVE Spain as well as critical validation from Earmilk and Stereofox. 
          </p>
          <p>
          With her fragile but powerful sound, Rumia is to be a fixture in the indie pop scene, releasing her first debut album this year.
          </p>
          <p>
          'Expansive tales from the depths of her heart, driven by polished instrumentals that move seamlessly across genres like trip hop and modern pop led by the power of her storytelling abilities.' - EARMILK 
          </p>
          </div>
        </Grid>
      </Grid>

     { getConcerts() }

      {/* Begin Netlify Signup Form  */}
      <form method="post" name="contact">
        <input type="hidden" name="form-name" value="contact" />
        <Grid container justify="center" className="Body-subscribe-container">
          <Grid item md={2} xs={6}>
            <TextField id="outlined-basic" label="EMAIL" variant="outlined" name="email" className="Subscribe-email-field" />
          </Grid>        
          <Grid item md={1} xs={3}>
            <input type="submit" value="SUBSCRIBE" name="subscribe" className="Subscribe-button"/>
          </Grid>
          <div aria-hidden="true">
            <input type="text" name="b_a4c940ac69a931eff5bc34048_058f9bdfc6" tabIndex="-1" value="" readOnly={true} className="hidden-element"/>
          </div>
        </Grid>
      </form>

      {/* End mc_embed_signup */}

      <footer>
        <div className="Footer-social-links">
          <a href={itunesURL} target="_blank" rel="noopener noreferrer">
            ITUNES
          </a>
          &nbsp;
          /
          &nbsp;
          <a href={spotifyURL} target="_blank" rel="noopener noreferrer">
            SPOTIFY
          </a>
          &nbsp;
          /
          &nbsp;
          <a href={instagramURL}  target="_blank" rel="noopener noreferrer">
            INSTAGRAM
          </a>
          &nbsp;
          /
          &nbsp;
          <a href={youtubeURL}  target="_blank" rel="noopener noreferrer">
            YOUTUBE
          </a>
          &nbsp;
          /
          &nbsp;
          <a href={facebookURL}  target="_blank" rel="noopener noreferrer">
            FACEBOOK
          </a>
        </div>

        <div className="Footer-band-name">
          RUMIA
        </div>
      </footer>
    </div>
  );
}

function getConcerts() {

  return (
    <Grid container justify="center" className="Body-concerts-container">
        <Grid item xs={2} md={2} className="Body-concerts-title">
          CONCERTS
        </Grid>
        <Grid item xs={9} md={6} className="Body-concerts-list">
        {
          concerts.length?
          concerts.map((item) =>
          <Grid container className="Body-concerts-item" key={item.linkURL}>
            <Grid item xs={4}>
            {item.date}
            </Grid>
            <Grid item xs={4}>
              {item.name}
            </Grid>
            <Grid item xs={4}>
              <a target="_blank" href={item.linkURL} rel="noopener noreferrer">
                {item.linkName}
              </a>
            </Grid>
          </Grid>
          )
          :
          <div container="true" className="Body-concerts-item">
            No concerts scheduled at the moment
          </div>
        }
        </Grid>
      </Grid>
  )
}

export default App;
